<template>
    <v-app>
        <v-card>
            <v-card-text>
                <v-stepper alt-labels v-model="currentStep">
                    <template >
                        <v-stepper-header>
                            <v-stepper-step
                                :step="step.key"
                                :complete="false"
                                v-for="(step,index) in steps"
                                :key="index.toString()+'A'"
                                editable
                                color="#f95812"
                            >
                                {{step.name}}
                            </v-stepper-step>

                            <!--<v-divider 
                                v-for="(step,index) in steps"
                                :key="index.toString()+'B'"
                            ></v-divider>-->

                            <v-stepper-step color="success" :step="'finished'" v-if="success" complete>
                                Terminado
                            </v-stepper-step>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content :step="etapa.key" v-show="!loading && !success" v-for="(etapa,index) in steps" :key="index">
                                <v-form
                                        :ref="'form'+(index+1)"
                                        v-model="steps[index].valid"
                                        v-if="currentStep == 1"
                                    >
                                        <div class="row">
                                            <div class="col-md-12">
                                                <v-text-field
                                                    v-model="newClient.name"
                                                    :counter="200"
                                                    :rules="[v => !!v || 'Debes completar este campo']"
                                                    label="Nombre de tienda"
                                                    required
                                                    prepend-icon="icon-orders"
                                                ></v-text-field>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <v-text-field
                                                    v-model="newClient.code"
                                                    :counter="200"
                                                    :rules="[v => !!v || 'Debes completar este campo']"
                                                    label="SIL Code"
                                                    required
                                                    prepend-icon="mdi-code-tags"
                                                ></v-text-field>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <h5>Order States {{newClient.name}}</h5>
                                                <v-simple-table>
                                                    <template v-slot:default>
                                                    <thead>
                                                        <tr> 
                                                            <th class="text-left">Name</th>
                                                            <th class="text-left">Type</th>
                                                            <th class="text-left">Order</th>
                                                            <th class="text-left">Colour</th>
                                                            <th class="text-left">&nbsp;</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(item,index) in newClient.orderStates" :key="index">
                                                            <td>{{ item.name }}</td>
                                                            <td>{{ item.type }}</td>
                                                            <td>{{ item.order }}</td>
                                                            <td class="d-flex flex-column">
                                                                <button
                                                                :class="'btn btn-sm btn-'+item.colour"
                                                                v-text="item.colour"
                                                                ></button>
                                                            </td>
                                                            <td><v-btn color="primary" @click="removeOrderState(item)" icon v-if="item.removable"><v-icon>mdi-close</v-icon></v-btn></td>
                                                        </tr>
                                                        <tr>
                                                            <td><h6 style="color: #F95812">Agregar nuevo estado</h6></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td><v-text-field v-model="newOrderState.name" label="Name"></v-text-field></td>
                                                            <td><v-text-field v-model="newOrderState.type" label="Type"></v-text-field></td>
                                                            <td><v-text-field type="number" v-model="newOrderState.order" label="Order"></v-text-field></td>
                                                            <td><v-select :items="['outline-info','warning','primary','danger','success','dark']" v-model="newOrderState.colour" label="Colour"></v-select></td>
                                                            <td><v-btn @click="addOrderState" :disabled="Object.keys(newOrderState).some(e => !newOrderState[e])" color="success" icon ><v-icon>mdi-plus</v-icon></v-btn></td>
                                                        </tr>
                                                    </tbody>
                                                    </template>
                                                </v-simple-table>
                                            </div>
                                        </div>
                                </v-form>
                                <v-form
                                        :ref="'form'+(index+1)"
                                        v-model="steps[index].valid"
                                        v-if="currentStep == 2"
                                    >
                                        <div class="row">
                                            <div class="col-md-12">
                                                <v-select
                                                    v-model="newClient.carriers"
                                                    :items="Object.keys(carriersData)"
                                                    :rules="[v => !!v.length || 'Debes completar este campo']"
                                                    label="Sync Carriers"
                                                    multiple
                                                    required
                                                    prepend-icon="mdi-truck"
                                                ></v-select>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <v-select
                                                    v-model="newClient.carrier_default"
                                                    :items="Object.keys(carriersData)"
                                                    :rules="[v => !!v || 'Debes completar este campo']"
                                                    label="Carrier DEFAULT"
                                                    required
                                                    prepend-icon="mdi-truck-fast"
                                                ></v-select>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6" v-for="(carrier,index) in newClient.carriers" :key="index.toString()+'C'">
                                                <v-card outlined >
                                                    <v-card-title class="headline">Parámetros {{carrier}}</v-card-title>
                                                    <v-card-text>
                                                    <div class="row" v-for="(campo,index) in Object.keys(carriersData[carrier])" :key="index.toString()+carrier"  >
                                                        <div class="col-md-12">
                                                            <v-text-field :label="campo" :rules="[v => !!v || 'Debes completar este campo']" v-model="carriersData[carrier][campo]"></v-text-field>
                                                        </div>
                                                    </div>
                                                    </v-card-text>
                                                </v-card>
                                            </div>
                                        </div>
                                </v-form>
                                <v-form
                                        :ref="'form'+(index+1)"
                                        v-model="steps[index].valid"
                                        v-if="currentStep == 3"
                                    >
                                        <div class="row">
                                            <div class="col-md-12">
                                                <v-select
                                                    v-model="newClient.marketplaces"
                                                    :items="Object.keys(marketplacesData)"
                                                    :rules="[v => !!v.length || 'Debes completar este campo']"
                                                    label="Sync Marketplaces"
                                                    multiple
                                                    required
                                                    prepend-icon="mdi-storefront"
                                                ></v-select>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6" v-for="(marketplace,index) in newClient.marketplaces" :key="index.toString()+'M'">
                                                <v-card outlined >
                                                    <v-card-title class="headline">Parámetros {{marketplace}}</v-card-title>
                                                    <v-card-text>
                                                    <div class="row" v-for="(campo,index) in Object.keys(marketplacesData[marketplace])" :key="index.toString()+marketplace"  >
                                                        <div class="col-md-12">
                                                            <v-text-field :label="campo" :rules="[v => !!v || 'Debes completar este campo']" v-model="marketplacesData[marketplace][campo]"></v-text-field>
                                                        </div>
                                                    </div>
                                                    </v-card-text>
                                                </v-card>
                                            </div>
                                        </div>
                                </v-form>
                                <v-form
                                        :ref="'form'+(index+1)"
                                        v-model="steps[index].valid"
                                        v-if="currentStep == 4"
                                    >
                                        <v-container fluid>
                                            <v-list-item
                                            
                                            :color="!!newClient.name ? 'success':'#f95812'"
                                            >
                                                <v-list-item-icon>
                                                    <v-icon :color="(!!newClient.name && !!newClient.name) ? '#F95812':'rgba(0, 0, 0, 0.54)'" v-text="!!newClient.name ? 'check':'close'"></v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content>
                                                    <v-list-item-title>Módulo Order</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item
                                            
                                            :color="(!!newClient.carriers.length && !!newClient.carrier_default) ? 'success':'red darken-3'"
                                            >
                                                <v-list-item-icon>
                                                    <v-icon :color="(!!newClient.carriers.length && !!newClient.carrier_default) ? '#F95812':'rgba(0, 0, 0, 0.54)'" v-text="(!!newClient.carriers.length && !!newClient.carrier_default) ? 'check':'close'"></v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content>
                                                    <v-list-item-title>Módulo Shipment</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item
                                            
                                            :color="etapa.valid ? 'success':'red darken-3'"
                                            >
                                                <v-list-item-icon>
                                                    <v-icon :color="(!!newClient.marketplaces.length) ? '#F95812':'rgba(0, 0, 0, 0.54)'" v-text="(!!newClient.marketplaces.length) ? 'check':'close'"></v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content>
                                                    <v-list-item-title>Módulo Marketplace</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-container>
                                </v-form>
                                <div class="row">
                                    <div class="col-md-3">
                                    <v-btn class="btn-starkoms-primary" dark  v-if="steps[index].name != 'Finalización'" :disabled="!steps[index].valid" @click="finalizarEtapa" >Finalizar {{etapa.name}}</v-btn>
                                    <v-btn class="btn-starkoms-primary" dark v-if="steps[index].name == 'Finalización'" :disabled="!newClient.marketplaces.length || !(!!newClient.carriers.length && !!newClient.carrier_default) || !newClient.name || !newClient.code" @click="finalizarEtapa" depressed>Finalizar todo</v-btn>
                                    </div>
                                </div>
                            </v-stepper-content>
                            <v-container style="height: 300px;" v-show="loading">
                                <v-row
                                    class="fill-height"
                                    align-content="center"
                                    justify="center"
                                >
                                    <v-col
                                    class="subtitle-1 text-center"
                                    cols="12"
                                    >
                                    {{loadingMessage}}
                                    </v-col>
                                    <v-col cols="6">
                                    <v-progress-linear
                                        color="deep-purple accent-4"
                                        indeterminate
                                        rounded
                                        height="6"
                                    ></v-progress-linear>
                                    </v-col>
                                </v-row>
                            </v-container>
                            <v-stepper-content :step="'finished'" v-show="success">
                                <v-container style="height: 300px;" v-show="loading">
                                <v-row
                                    class="fill-height"
                                    align-content="center"
                                    justify="center"
                                >
                                    <v-col
                                    class="subtitle-1 text-center"
                                    cols="12"
                                    >
                                        <v-icon color="green" size="x-large">mdi-checkbox-marked-circle-outline</v-icon>
                                    </v-col>
                                </v-row>
                                </v-container>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
                        
            </v-card-text>
        </v-card>
    </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
export default {
    created(){
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Configuraciones", route: "/configuraciones" },
            { title: "Client Onboarding", route: "/configuraciones/client-onboarding" },
        ]);
    },
    data(){
        return {
            currentStep: 1,
            success: false,
            loading: false,
            loadingMessage: 'JJJJJAAA',
            steps:[
                {
                    name: 'Módulo Order', key: 1, loading: false, completed: false, valid: false,
                },
                {
                    name: 'Módulo Shipment', key: 2, loading: false, completed: false, valid: false,
                },
                {
                    name: 'Módulo Marketplace', key: 3, loading: false, completed: false, valid: false,
                },
                {
                    name: 'Finalización', key: 4, loading: false, completed: false, valid: false,
                },
            ],
            newClient: {
                name: undefined,
                code: undefined,
                orderStates: [
                    {type: 'DEFAULT_IN_OMS', colour: 'outline-info', name: 'Ingresado', order: 1, removable: false},
                    {type: 'DEFAULT_IN_PACKAGING', colour: 'warning', name: 'Preparando', order: 2, removable: false},
                    {type: 'DEFAULT_OUT_PACKAGING', colour: 'warning', name: 'Preparado', order: 3, removable: false},
                    {type: 'DEFAULT_IN_SHIPMENT', colour: 'primary', name: 'Empacado', order: 4, removable: false},
                    {type: 'DEFAULT_OUT_SHIPMENT', colour: 'primary', name: 'Listo para Enviar', order: 5, removable: false},
                    {type: 'DEFAULT_IN_CARRIER', colour: 'danger', name: 'Entregado a Transporte', order: 6, removable: false},
                    {type: 'DEFAULT_IN_OMS', colour: 'danger', name: 'En Ruta', order: 7, removable: false},
                    {type: 'DEFAULT_IN_CUSTOMER', colour: 'success', name: 'Entregado a Cliente', order: 8, removable: false},
                    {type: 'DEFAULT_TO_CARRIER_CD', colour: 'danger', name: 'En Tránsito a CD', order: 6.1, removable: false},
                    {type: 'DEFAULT_IN_CARRIER_CD', colour: 'danger', name: 'En Courier CD', order: 6.2, removable: false},
                    {type: 'DEFAULT_TO_CARRIER_HUB', colour: 'danger', name: 'En Tránsito a HUB Regional Courier', order: 6.3, removable: false},
                    {type: 'DEFAULT_IN_CARRIER_HUB', colour: 'outline', name: 'En HUB Regional Courier', order: 6.4, removable: false},
                    {type: 'DEFAULT_CANCELED', colour: 'dark', name: 'Cancelado', order: 9, removable: false},
                ],
                carriers: [],
                marketplaces: [],
                carrier_default: undefined,
            },
            newOrderState: {
                name: undefined,
                type: undefined,
                order: undefined,
                colour: undefined,
                removable: true,
            },
            carriersData:{
                'Chilexpress':{
                    'subscriptionKey': undefined,
                    'rut': 'DEFAULT',
                    'customerCardNumber': undefined,
                    'countyOfOriginCoverageCode': undefined,
                    'contactName': undefined,
                    'urlCreateShipment': undefined,
                },
                '99 Minutos':{
                },
                'Envíame':{},
                'Starken':{},
                'Mercadoenvios':{}
            },
            marketplacesData:{
                'JumpSeller Biofoods':{
                    'url':undefined,
                    'login':undefined,
                    'token':undefined,
                },
                'Woocommerce':{
                    'url':undefined,
                    'consumer_key':undefined,
                    'consumer_secret':undefined,
                },
                'Mercadolibre':{
                    'app_id':undefined,
                    'secret_key':undefined,
                    'redirect_uri':undefined,
                    'callback_uri':undefined,
                    'access_token':undefined,
                    'seller_id':undefined,
                    'refresh_token':undefined,
                    'code':undefined,
                    'site_id':undefined,
                    'seller_nickname':undefined,
                    'token_expires':undefined,
                },
                'Mercadoripley':{
                    'api_key':undefined,
                    'url':undefined,
                },
                'Prestashop':{
                    'shop_url':undefined,
                    'api_key':undefined,
                },
            }
        }
    },
    methods: {
        removeOrderState(item)
        {
            const index = this.newClient.orderStates.indexOf(item);
            this.newClient.orderStates.splice(index,1);
        },
        addOrderState()
        {
            this.newClient.orderStates.push(this.newOrderState);
            this.newOrderState = {
                name: undefined,
                type: undefined,
                order: undefined,
                colour: undefined,
                removable: true
            };
        },
        finalizarEtapa()
        {
            if(this.currentStep == this.steps.length){
                this.sendForm();
            }else{
                this.currentStep++;
            }
        },
        sendForm()
        {
            var vm = this;
            this.loading = true;
            this.loadingMessage = 'Creando Store en Módulo Order...';
            this.axios({
                url: 'order/client_onboarding',
                method: 'POST',
                data: {
                    store_name: this.newClient.name,
                    order_states: this.newClient.orderStates,
                    code: this.newClient.code
                }
            }).then( response => {
                vm.loadingMessage = 'Creando Store en Módulo Shipment...';
                vm.axios({
                    url: 'shipment/client_onboarding',
                    method: 'POST',
                    data: {
                        store_name: vm.newClient.name,
                        carriers: vm.newClient.carriers.map(e => ({
                            name: e,
                            data: Object.keys(vm.carriersData[e]).length ? vm.carriersData[e] : null
                        })),
                        carrier_default: vm.newClient.carrier_default,
                    }
                }).then( response => {
                    vm.loadingMessage = 'Creando Store en Módulo Marketplace...';
                    vm.axios({
                        url: 'marketplace/client_onboarding',
                        method: 'POST',
                        data: {
                            store_name: vm.newClient.name,
                            marketplaces: vm.newClient.marketplaces.map( e => ({
                                name: e,
                                data: Object.keys(vm.marketplacesData[e]).length ? vm.marketplacesData[e] : null
                            }))
                        }
                    }).then( response => {
                        vm.loadingMessage = 'Creando Store en Módulo Inventory...';
                        vm.axios({
                            url: 'inventory/client_onboarding',
                            method: 'POST',
                            data: {
                                store_name: vm.newClient.name,
                                code: vm.newClient.code
                            }
                        }).then( response => {
                            
                            vm.loading = false;
                            vm.success = true;
                            vm.currentStep = 'finished';
                        }).catch( error => {

                        });
                    }).catch( error => {

                    });
                }).catch( error => {

                });
            }).catch( error => {

            });
        }
    },
    watch: {
      
    },
}
</script>
 <style >
 .v-icon.notranslate.mdi.mdi-code-tags.theme--light{
     color: #f95812;
 }
 .v-icon.notranslate.mdi.mdi-truck.theme--light{
     color: #f95812;
 }
 .v-icon.notranslate.mdi.mdi-truck-fast.theme--light{
     color: #f95812;
 }
 .v-icon.notranslate.mdi.mdi-storefront.theme--light{
     color: #f95812;
 }
 </style>